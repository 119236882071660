.blogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

#titleBlog,
.titlePosts,
#articlesTitle {
  font-weight: bold;
}

.blogContainer .latestPosts {
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
}

.articlesRow {
  display: grid;
  grid-template-columns: [a1] 1fr [a2] 1fr [a3] 1fr [b] 1fr;
  grid-template-rows: [unique] 100%;
  column-gap: 5%;
}

.blogContainer .articlesCont {
  display: flex;
  text-align: center;
  padding: 3%;
  flex-direction: column;
  height: 100%;
}

.blogContainer .articles {
  display: grid;
  grid-template-columns: [a1] 1fr [a2] 1fr [a3] 1fr [b] 1fr;
  column-gap: 5%;
  row-gap: 4vh;
}

.blogContainer #moreArts {
  font-family: "Roboto-Light";
  font-size: 2vw;
}

.articlesRow .suggestions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: #11183a;
  color: white;
}
.suggestions #suggestionsTitle {
  font-weight: bold;
  margin-top: 1%;
  margin-left: 5%;
  text-align: left;
}

.articlesRow .suggestions li {
  list-style-type: none;
  margin-left: 5%;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
}
.suggestions li span {
  font-size: 2rem;
  font-family: "Roboto-Normal";
}
.suggestions i {
  font-size: 2.5rem;
}

.articlesRow .suggestions li:hover {
  font-weight: bold;
  cursor: pointer;
}

.articlesRow .suggestions a {
  margin: 0% 5%;
  border-bottom: 1px;
  border-bottom-style: solid;
}

.enterArticle {
  animation: articlePopUp 2s;
}

.enterSuggestions {
  animation: sectionEnterRight 1.7s;
}

@media screen and (max-width: 1000px) {
  .latestPosts {
    display: none;
  }
}

@media (max-width: 750px) {
  .blogContainer .articles {
    grid-template-columns: [a1] 1fr [b] 1fr;
  }
  .postTxt {
    display: none;
  }
}

@media (max-width: 350px) {
  .blogContainer .articles {
    grid-template-columns: [a1] 1fr;
  }
}
