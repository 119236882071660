.Title h1 {
  font-family: "Roboto-Bold";
  font-size: 300%;
}

/* Font size for medium-sized screens */
@media (min-width: 768px) {
  .Title h1 {
    font-size: 550%; /* Adjust the font size as needed */
  }
}

/* Font size for large-sized screens */
@media (min-width: 992px) {
  .Title h1 {
    font-size: 800%; /* Adjust the font size as needed */
  }
}

@media (min-width: 1200px) {
  /* Adjust the width breakpoint as needed for xl screens */
  .img {
    width: "100vw";
    height: "100vh";
    object-fit: "cover";
  }
}
