#aboutUs {
  color: var(--primary-color);
  background-color: var(--secondary-color);
  height: auto;
  display: flex;
  flex-direction: column;
}

.fullCentered {
  color: var(-secondary-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#titleAboutUs {
  height: 40vh;
  width: 100%;
  background-image: url("../../../assets/pages/about/goal9.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto-Bold";
}

#subtitleAboutUs {
  height: fit-content;
  padding-inline: 15%;
  background-color: var(--blue);
  text-align: center;
}

#subtitleAboutUs h2 {
  font-family: "Roboto-Medium";
  margin-top: 2%;
}

#subtitleAboutUs p {
  text-align: justify;
}
#whoWeAre {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
#whoWeAre video {
  max-width: 45%;
  margin: 2%;
  margin-top: 0;
}

#unGoals {
  padding-block: 8vh;
  text-align: center;
  height: fit-content;
}

#unGoals h1 {
  font-family: "Roboto-Medium";
  font-size: 2rem;
}

#unGoals #motto {
  height: fit-content;
  padding-inline: 15%;
  text-align: center;
}

#unGoals #motto p {
  text-align: justify;
}

#unGoals #unGoalsImg {
  padding-inline: 1%;
}

#unGoals #unGoalsImg img {
  padding: 0.5%;
}

.aboutTxtImg {
  display: flex;
  flex-direction: row;
  margin-bottom: 3%;
  background-color: var(--ghostBlue);
}

#secondRowText {
  flex: 1;
}

.rowTextHalf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 7%;
  padding-block: auto;
  font-size: 1vw;
  text-align: justify;
}

@media screen and (max-width: 900px) {
  .aboutTxtImg {
    flex-direction: column;
  }
  .rowTextHalf {
    font-size: inherit;
    padding-inline: 14%;
    padding-block: auto;
  }
  #whoWeAre video {
    display: none;
  }
}

#secondRowImg,
#fourthRowImg {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#secondRowImg img,
#fourthRowImg img {
  object-fit: cover;
  max-width: 50vw;
}

#aboutImpact {
  padding-block: 10vh;
  text-align: center;
}

#aboutImpact h2 {
  font-family: "Roboto-Medium";
}

#aboutImpact #theseImpacts {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.valueCard {
  width: 20vw;
  min-height: 35vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.valueCard img {
  max-width: 10vh;
}

#fourthRowImg {
}

#fourthRowText {
}

#fourthRowImg img {
  max-width: 100%;
  min-height: 100%;
}
