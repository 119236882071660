.spinner {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #333;
    animation: spin .5s linear infinite;
    -webkit-animation: spin .5s linear infinite;
}

#articlePageContainer{
    color: var(--primary-color);
    background-color: var(--secondary-color);
}

#articleSpanner{
    width: 100%;
    height: 35vh;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#arrowBack{
    position: relative;
    left: 2%;
    width: fit-content;
    display: flex;
    margin-bottom: 2%;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    cursor: pointer;
}

#arrowBack i{
    font-size: 2rem;
}


#articleContainer{
    background-color: var(--secondary-color);
    position: relative;
    top: -17vh;
    padding-top: 3%;
    padding-inline: 2%;
    margin-inline: 10%;
    padding-inline: 7%

}
#articleTitle{
    font-family: 'Roboto-Black';
}

#dateAuthor{
    margin-block: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* #artAuthor{
    position: relative;
    left: 5vh;
} */
#imgContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#articleImg{
    max-width: 100%;
    max-height: 50vh;
}
#descriptionArticle{
    font-family: 'Roboto-Bold';
}

#descriptionArticle, #derivedHTML{
    text-align: justify;
    text-justify: inter-word;

}

.blogpost-share, #arrowBack{
    font-size: 32px;
    opacity: 0.8;
    margin: 10px;
    cursor: pointer;
}

.blogpost-share:hover{
    opacity: 1;
}

.blogpost-share:active {
    opacity: 0.8;
}

.blogpost-share[title="facebook"] {
    fill: #1877F2;
    color: #1877F2;
}

.blogpost-share[title="copy"] {
    fill: #D2D2D2;
    color: #D2D2D2;
}

