.container404{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Roboto-Light";
}
#img404{
    margin: 5vh 0;
    height: 30vh;
    border-radius: 10%;
}
.container404 .buttons404{
    width: 30vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:
}