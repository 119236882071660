h1 {
  font-family: "Roboto-BlackItalic";
}

p {
  font-family: "Roboto-Light";
  color: white;
}

li {
  color: white;
}

#partnerWithUs {
  height: 100vh; /* Set the height of the section to 100% of the viewport height */
}

.partnercard {
  height: 100%; /* Set the height of the container to 100% */
}

.custum-card {
  /* Adjust the width of the card */
  width: 1200px; /* Set a maximum width for the card */
  border-radius: 20px; /* Add border-radius for rounded corners */
}
