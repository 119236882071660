.sponsorCard {
    width: 25%;
    color: var(--secondary-color);
    background-color: var(--primary-color);
    margin: 2%;
    overflow: hidden;
    cursor: pointer;
}

/* .sponsorCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.sponsorCard .card-img {
  margin: auto;
  height: 100%;
  width: 100%;
  background-color: white;
}

.sponsorCard img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.sponsorName{
  width:100%;
  text-align:center;
}

.goldSponsor:hover {
    border-color: gold !important;
}

.silverSponsor:hover {
    border-color: silver !important;
}

.bronzeSponsor:hover {
    border-color: #cd7f32 !important;
}

.diamondSponsor:hover{
    border-color: #b9f2ff !important;
}


.goldSponsor .card-title {
    font-weight: bold;
}

.medal {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
  .gold {
    color: gold;
  }
  
  .silver {
    color: silver;
  }
  
  .bronze {
    color: #cd7f32;
  }
  
  .diamond {
    color: #b9f2ff;
  }

.modal-gold-title{
  text-shadow: 1px 5px gold;
}
.modal-diamond-title{
  text-shadow: 1px 5px #b9f2ff;
}
.modal-silver-title{
  text-shadow: 1px 2px silver;
}
.modal-bronze-title{
  text-shadow: 1px 2px #cd7f32;
}
    
@media screen and (max-width:600px) {
  .sponsorCard{
    width:50%;
  }
}
@media screen and (min-width:1000px) {
  .diamondSponsor {
    margin-inline: 7vw;
  }
}