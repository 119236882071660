#teamContainer {
  /* border-style: solid;
    border-color: red; */
  /*vh stands for Relative to 1% of the height of the viewport* */
  min-height: 100vh;
}

#chooseGroupButton {
  color: white;
  text-align: center;
}

#teamName {
  font-size: 2em;
  font-family: "Roboto-Bold";
  color: white;
  text-align: center;
  margin-bottom: 3%;
}

#chooseGroupButtonText {
  font-size: 1.35em;
  font-family: "Roboto-Light";
  color: rgb(201, 201, 201);
}

#spinner {
  color: white;
  display: none;
}
#chooseGroupButtonText:hover {
  color: white;
  cursor: pointer;
}
#teamGroupRow {
  padding-bottom: 3%;
}

#teamTitleRow {
  /* border-style: solid; */
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 6%;
}
#teamTitleText {
  font-size: 5em;
  font-family: "Roboto-Bold";
}
#teamdescriptionRow {
  color: white;
  text-align: center;
  margin-bottom: 3%;
  font-style: italic;
}
#teamPicturesRow {
  justify-content: center;
  text-align: center;
  color: white;
}

#yearTitle {
  color: rgb(201, 201, 201);
  text-align: center;
  padding-top: 10px;
  font-family: "Roboto-Light";
  font-size: x-large;
}

#yearTitle:hover {
  color: white;
  cursor: pointer;
}
#teamYearCol {
  margin-top: 5%;
}
#stickyDiv {
  position: sticky;
  top: 10%;
}

#memberBox {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  #teamYearCol {
    width: 100%;
  }

  #teamList {
    width: 100%;
    margin-top: 8px;
  }
  #ourTeam {
    width: 100%;
    margin-top: 8px;
  }
  #stickyDiv {
    display: flex;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: gray;
  }
  #yearTitle {
    margin-left: 5px;
    margin-right: 5px;
  }
}
