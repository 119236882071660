@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: local('Roboto'), url(../assets/font/Roboto/Roboto-ThinItalic.ttf) format('truetype');
}
