.headerLinks{
    cursor: pointer;
}

.headerLinks:hover {
    opacity: 0.8;
    font-weight: bold;
}

.selectedLink{
    font-weight: bold;
    color: aliceblue !important;
    text-decoration: underline aliceblue;
}

.headerLinkSocial:hover{
    color: var(--ghostBlue);
}

.navbar-toggler-icon{
    /*White Burger Image*/
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.headearNabsLinks{
    margin-left: 1%;
    color: fuchsia;
}

.nav-link-page-width{
    min-width: fit-content;
    width: 80px;
    text-align: center;
}

.car{
    display: none;
    opacity: 1;
    font-size: medium;
}

@media (max-width: 1000px){
    .headerLinkSocial{
        display: none;
    }
    #headerBar{
        background-color: var(--tertiary-color) !important;
    }
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
  }
  
  nav.hidden {
    opacity: 0;
  }
  
  @media (max-width: 1000px) {
    nav {
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      opacity: 1;
    }
  }