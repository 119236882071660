#containerPost {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* grid-template-columns: 1fr;
    grid-template-rows: auto; */
  cursor: pointer;
  padding: 3%;
  flex-wrap: wrap;
}

#containerPost h1 {
  font-family: "Roboto-Regular", Arial, Helvetica, sans-serif;
  text-align: center;
  min-height: 5rem;
  font-size: 2rem;
}

#containerPost p {
  font-size: 1.2vw;
}

#containerPost .postTxt {
  text-align: justify;
  padding: 0 10%;
}

#containerPost .datePost {
  font-family: "Roboto-Light";
  font-size: 1vw;
  margin-left: auto;
}

#containerPost:hover {
  font-weight: bold;
}

.imagePost {
  object-fit: cover;
}
