#titleSponsors {
  width: 100%;
  padding-inline: 10%;
  padding-block: 5%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

#titleSponsors h1 {
  font-family: "Roboto-Black";
}

#sponsorsSponsors {
  color: var(--primary-color);
}
.sponsorDiv {
  padding-inline: 4%;
}
.sponsorDiv h3 {
  font-family: "Roboto-BlackItalic";
  margin: auto;
  margin-left: 9%;
}

.sponsorRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bronzeSponsorsContainer {
  background-color: fuchsia;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

#contactSponsorsContainer {
  /* background-image: url("../../../assets/pages/Sponsors/sponsors_car.jpg"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* padding: 10%; */
  color: white;
  font-family: "Roboto-Black";
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.bi-chevron-down {
  font-size: 2rem;
  display: block;
  margin: 0 auto;

  animation-name: blink;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Sponsor form
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/
.required {
  color: red;
}
