#contact-us-title {
  color: aliceblue;
  text-align: center;
  font-family: "Roboto-Mediu";
}

#leader-contact-info {
  color: aliceblue;
  font-size: large;
  font-family: "Roboto-Light";
  justify-content: center;
}

#contact-text {
  color: aliceblue;
  font-family: "Roboto-MediumItalic";
  text-shadow: 1px 10px rgba(0, 0, 238, 0.2);
  padding: 5%;
  text-align: left;
}

#leader-information {
  text-align: center;
}

#leader-information h2 {
  font-weight: bold;
}

#leader-information h2,
#leader-information h4,
#leader-information a {
  margin-bottom: 10%;
  color: aliceblue;
}

#leader-information a:hover {
  color: var(--ghostBlue);
}

#adress-info span {
  margin: 2%;
}

#adress-info {
  margin: 1%;
  border-left: 10px solid var(--blue);
  color: aliceblue;
  font-size: large;
  font-family: "Roboto-Light";
  text-align: center;
  padding: 5%;
  background-color: rgba(31, 31, 83, 0.13);
}

#leader-picture {
  text-align: center;
}

#general-details {
  color: aliceblue;
  font-family: "Roboto-MediumItalic";
  text-align: left;
}

.contact-details {
  padding-top: 2%;
  padding-inline-start: 3%;
}

#pic-col,
#cont-col,
#adr-col,
#map-col {
  text-align: left;
}

#map-col {
  text-align: center;
  padding: 2%;
}

#general-details h2 {
  color: aliceblue;
}

#contactus-icons i,
#contactus-icons a {
  margin: 2%;
  color: aliceblue;
}
#contactus-icons i:hover {
  margin: 2%;
  color: var(--ghostBlue);
}

#general-mail {
  color: aliceblue;
}
#general-mail:hover {
  color: var(--ghostBlue);
}

#q_A {
  color: aliceblue;
  text-align: left;
  padding: 7%;
  text-align: justify;
}

#q_A .h2 {
  text-align: center;
}

#q_A #general-details {
  margin-bottom: 7%;
}

#q_A #general-details li {
  font-family: "Roboto-Light";
}
