.PartnerShipCard {
  display: flex;
  justify-content: center;
  padding-top: 5vh;
}

.card-container {
  position: relative;
  display: flex;
  align-items: center;
}

.card {
  width: 40vh;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 300px !important;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
}

.card-subtitle {
  font-size: 14px;
}

.arrow::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #ffffff; /* Change the color to your desired arrow color */
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
}
