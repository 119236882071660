.historyTitle h1 {
  font-family: "Roboto-Bold";
}

.historySection h1 {
  font-family: "Roboto-BlackItalic";
}

p {
  font-family: "Roboto-Light";
}

@media (min-width: 1199px) {
  .large-screen-style {
    height: 100vh;
  }
}
