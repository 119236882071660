#memberPictureBox {
    background-color: var(--tertiary-color);
	
	vertical-align: top;
	border-radius: 7px;
	margin: 10px;
	position: relative;
	display: inline-block;
	
	overflow: hidden;
    font-family: 'Roboto-Light';
	display:flex;
	flex-direction: column;
	align-items:center ;
    width: 200px;
    
}
#memberImage{
   
  	width: 100%;
	
}
#memberInfo{
	height: 100px;
	width: 100%;
	align-items:center ;
	overflow: hidden;
    font-family: 'Roboto-Light';
	display:flex;
	flex-direction: column;
	align-items:center ;
	

}
#position {
   	
	padding: 5px 0 0 0;
	font-size: 14px;
    color: white;
    font-family: 'Roboto-Light';
}


#nameline{
    color: white;
	font-weight: bold;
	font-size: 16px;
    font-family: 'Roboto-Light';
	padding-top: 7px;
	padding-bottom: 2px;
}

#profileborder{
	height: 1px;
	border: 0;
	color: #302f2f;
	background-color: #424141;
	width: 80%;
	
}

#socialMediaBar{
	color: white;
	background-color: white;
}

  .overlay {
	position: absolute;
	bottom: 0;
	background: white;
	background: white; /* Black see-through */
	color: #f1f1f1;
	width: 100%;
	height: 100px;
	transition: .5s linear;
	opacity:0;
	color: white;
	padding: 10px;
	text-align: center;
  }
  
  /* When you mouse over the container, fade in the overlay title */
  #memberPictureBox:hover .overlay {
	opacity: 1;
  }
  #contactMemberNameOverlay{
	  font-size: 17px;
	  font-family: 'Roboto-Light';
	  color: black;
  }