.social-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-basis: 48px;
    flex-grow: 0;
    flex-shrink:1;
    gap: 1px;
  }
  .social-link {
    line-height: 0px;
  }
  .socialicon {
    color: rgb(255, 255, 255);
    fill: rgb(0, 0, 0);
    transition: all ease 250ms;
  }