:root {
  --primary-color: #ffffff;
  --secondary-color: #0a0e22;
  --tertiary-color: #000a18;
  --blue: #222e58;
  /*rgba: rgb(34, 47, 92)*/
  --white: #ffffff;
  --gosthWhite: rgb(255, 255, 255, 0.3);
  /*this white color has less opasity*/
  --green: #9BCBA7;
  --opaqueGreen: #9bcba78a;
  --ghostBlue: #7391ff;
  --fontSize: 1.1em;
  --screen-height: 100vh;
  --screen-width: 100vw;
}

body {
  margin: 0;
  font-family: 'Roboto-Light';
  font-size: var(--fontSize);
}

.pageContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
}

@media (min-width: 1000px) {
  .transparentHeader{
    position: relative;
    top: -74px;
  }
}

@media (max-width: 1000px) {
  #headerBar {
    position: fixed;
    width: 100%;
  }
  #route {
    position: static;
    margin-top: +74px;
  }
}


#centeredDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.primaryColor {
  color: var(--primary-color);
}

.secondaryColor {
  color: var(--secondary-color);
}
/* fontFamily = [Roboto-Light, Roboto-Medium, Roboto-Black, ...]*/

/*******
* Grid *
*******/

.grid {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  padding: 32px 0;
  text-align: center;
  width: auto;
}

.grid>* {
  margin: 24px;
}

.grid .grid {
  padding: 0;
}

.grid .whole {
  display: block;
}

@media only screen and (min-width: 640px) {
  .grid .half {
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 48px);
  }

  .grid .half h2 {
    text-align: left;
  }

  .grid .third {
    width: calc(33.33% - 48px);
    display: inline-block;
    vertical-align: top;
    margin-right: 16px;
  }

  .grid .third:last-child {
    margin-left: 16px;
    margin-right: 0;
  }

  .grid .two-thirds {
    display: inline-block;
    vertical-align: top;
    width: calc(66% - 48px);
  }
  .grid .two-thirds i{
    font-size: 3rem;
  }

  .grid *:only-child p {
    text-align: center;
  }
}

strong {
  width: 100%;
  display: inline-block;
}
