.countDown h1 {
  font-family: "Roboto-BlackItalic";
  color: #ff6633;
}

@media only screen and (min-width: 1080px) {
  .fixed-left {
    position: fixed;
    top: 25%;
    left: 0;
    transform: translateY(-50%);
  }
}

.custom-card {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 15px !important; /* Adjust the value as needed for the desired roundness */
  min-width: 300px;
}

.countdown-item {
  display: inline-block;
  text-align: center;
}

.countdown-value {
  color: white;
}

.countdown-label {
  color: white;
  display: block; /* Display the label under the countdown value */
}
