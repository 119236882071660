.mainSponsors .memberStats .infoSEM h1 {
  font-family: "Roboto-BlackItalic";
}

p {
  font-family: "Roboto-Light";
}

.homeContainer .applyField #apply {
  border-radius: 0px;
  grid-column: element;
  display: grid;
  font-family: "Roboto-BlackItalic";
  flex-direction: column;
  position: relative;
  left: 39%;
}

.homeContainer .applyField #apply-button {
  flex-direction: column;
  grid-column: element;
  font-family: "Roboto-BlackItalic";
  font-size: large;
  padding-bottom: 2%;
  position: relative;
  left: 47%;
}

.backgroundcolor h2 {
  color: #ff6633;
}

.btn-outline-light:hover {
  color: grey !important;
}

/* border-radius: 3px;
  border-image: linear-gradient(
      to right,
      red,
      orange,
      yellow,
      green,
      blue,
      indigo,
      violet,
      red
    )
    1; */

/* .homeContainer .applyField .button-suggested-action {
  background-image: linear-gradient(to right, #05253b, #011a2c, #05253b);
  border: 1px solid var(--tertiary-color);
  color: #fff;
  text-shadow: 0 1px #0000004d;
  margin-left: auto;
  margin-bottom: auto;
  border-radius: 5px;
  outline: none;
  padding: 12px;
  text-align: center;
  font-family: "Roboto-Light";
  width: 15%;
  text-decoration: none;
} */

.homeContainer .applyField {
  /* background-color: var(--secondary-color); */
  display: grid;
  grid-template-columns: [white] 5% [element] 90% [white] 5%;
}

.homeContainer .applyField .applyText {
  color: aliceblue;
  font-family: "Roboto-BlackItalic";
}

.homeContainer .applyField .applyText h1 {
  font-family: "Roboto-BlackItalic";
  text-align: justify;
}

#imgHomeAbout {
  border-end-end-radius: inherit;
  border-start-end-radius: inherit;
  width: 100%;
  height: 100%;
  background-size: cover;
}

#imgHomeAboutInv {
  border-end-start-radius: inherit;
  border-start-start-radius: inherit;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.fuelfighter-logo {
  position: relative;
  top: -7%;
}

@media screen and (max-width: 600px) {
  .homeContainer .applyField .button-suggested-action {
    width: 40%;
  }
}
